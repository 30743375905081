var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "dark mx-auto", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", lg3: "", "justify-center": "" } },
            [
              _c("v-img", {
                staticClass: "ma-3",
                attrs: {
                  height: "200px",
                  contain: "",
                  src: require("@/assets/logo.png"),
                  alt: "Zonnekamp"
                }
              }),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { dark: "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Username",
                          solo: "",
                          required: "",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.username,
                          callback: function($$v) {
                            _vm.username = $$v
                          },
                          expression: "username"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Enter your password",
                          solo: "",
                          required: "",
                          "append-icon": _vm.e1 ? "mdi-eye" : "mdi-eye-off",
                          type: _vm.e1 ? "password" : "text",
                          rules: _vm.passwordRules,
                          autocomplete: "off"
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.e1 = !_vm.e1
                          }
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "v-layout",
                        { staticClass: "right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "white",
                                "aria-label": "Login",
                                to: { path: "/forgot-password" }
                              }
                            },
                            [_vm._v("Wachtwoord vergeten")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "secondary",
                                "aria-label": "Login"
                              },
                              on: { click: _vm.handleSubmit }
                            },
                            [_vm._v("Login")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }