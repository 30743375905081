var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "dark", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", lg4: "", "justify-center": "" } },
            [
              _c("v-img", {
                staticClass: "ma-3",
                attrs: {
                  height: "150px",
                  contain: "",
                  src: require("@/assets/logo.png"),
                  alt: "Upgrade Estate"
                }
              }),
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _vm.validToken
                    ? _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { dark: "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Geef je wachtwoord in",
                              solo: "",
                              lazy: "",
                              required: "",
                              "append-icon": _vm.e1 ? "mdi-eye" : "mdi-eye-off",
                              type: _vm.e1 ? "password" : "text",
                              rules: _vm.passwordRules
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.e1 = !_vm.e1
                              }
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Herhaal je wachtwoord",
                              solo: "",
                              required: "",
                              lazy: "",
                              "append-icon": _vm.e1 ? "mdi-eye" : "mdi-eye-off",
                              type: _vm.e1 ? "password" : "text",
                              rules: _vm.passwordRulesRepeat
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.e1 = !_vm.e1
                              }
                            },
                            model: {
                              value: _vm.repeatPassword,
                              callback: function($$v) {
                                _vm.repeatPassword = $$v
                              },
                              expression: "repeatPassword"
                            }
                          }),
                          _c(
                            "v-layout",
                            { staticClass: "right" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    block: "",
                                    color: "secondary",
                                    "aria-label": "Login"
                                  },
                                  on: { click: _vm.handleSubmit }
                                },
                                [_vm._v("Login")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "h3",
                        { staticClass: "white--text text-lg-center" },
                        [
                          _vm._v(
                            "Het ingegeven token is niet geldig of is vervallen gelieven de aanvrager te contacteren "
                          ),
                          _c("br"),
                          _c("br"),
                          _vm._v(" indien u al een account hebt "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                color: "secondary",
                                "aria-label": "Login",
                                to: { path: "/login" }
                              }
                            },
                            [_vm._v("Naar Login Pagina")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }